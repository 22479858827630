import { Subtitle, mq } from '@kvdbil/components';
import React from 'react';
import styled from 'styled-components';
import Section from '~/App/shared/components/Layout/Section';
import { BlockButtonRecord } from '~/config/generated/graphql';
import FeatureBlocksItems from '~/App/views/Start/components/FeatureBlocksItems';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1.5rem;

  ${mq('tablet')} {
    gap: 1rem;
  }
`;

type Props = { title?: string | null; blockButtons?: BlockButtonRecord[] };

function FeatureBlocks({ title, blockButtons }: Props) {
  return (
    <Section backgroundColor="gray" spacing={3}>
      <Container>
        {title && <Subtitle>{title}</Subtitle>}
        <FeatureBlocksItems blockButtons={blockButtons} />
      </Container>
    </Section>
  );
}

export default FeatureBlocks;
